/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddPricing } from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import { RightArrownIcon } from "../../../SvgIcons/allIcons";
import { STEP_THREE } from "../../../globals/constants";
import {
  NumberWithdecimal,
  restrictAlpha,
} from "../../../utils/CommonFunction";
import Loader from "../../../Commons/Loader/Loader";

const Pricing = ({ productDetails }) => {
  const location = useLocation();
  const rid = new URLSearchParams(location?.search).get("rid");
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    daily_price: Yup.number()
      .required("This field is required")
      .moreThan(0, "Daily price must be greater than 0")
      .typeError("Invalid Input, must be a number")
      .integer("Please enter a interger"),
    minimum_rental_period: Yup.number()
      .min(1, "Maximum lenght is 1 characters.")
      .required("This field is required")
      .typeError("Invalid Input, must be a number")
      .integer("Invalid input"),
    maximum_rental_period: Yup.number()
      // .moreThan(
      //   Yup.ref("minimum_rental_period"),
      //   "Maximum rental period cannot be less then or equal to minimum rental period"
      // )
      .typeError("Invalid Input, must be a number")
      //.required("This field is required")
      .integer("Invalid input"),
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    resetForm,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      product_id: rid,
      daily_price: "",
      minimum_rental_period: "",
      maximum_rental_period: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true);
      let formData = new FormData();
      formData.append("product_id", rid);
      formData.append("daily_price", values.daily_price);
      formData.append("minimum_rental_period", values.minimum_rental_period);
      formData.append("maximum_rental_period", values.maximum_rental_period);
      if (productDetails?.steps >= STEP_THREE) {
        AddPricing(formData)
          .then((resp) => {
            setLoading(false);
            if (resp?.status === 200) {
              navigate("/lender/new-item?atab=fifth&rid=" + rid);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setLoading(false);
        showNotification("danger", "Please complete previous steps to proceed");
      }
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ".") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  const backAction = () => {
    navigate("/lender/new-item?atab=third&rid=" + rid);
  };

  useEffect(() => {
    if (rid && productDetails) {
      resetForm();
      setValues({
        ...values,
        daily_price: productDetails?.daily_price ?? "",
        minimum_rental_period: productDetails?.minimum_rental_period ?? "",
        maximum_rental_period: productDetails?.maximum_rental_period ?? "",
      });
    }
  }, [productDetails]);

  return (
    <>
      <p className="head16">
        Pricing & Availability
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Pricing
      </p>
      <Row>
        <Col lg={6} md={6}>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">
              Daily Price <span className="error">*</span>
            </p>

            <Form.Control
              type="text"
              name="daily_price"
              value={values?.daily_price}
              placeholder=" daily price (₹)"
              className="Enter daily price (₹)"
              onChange={handleChange}
              maxLength={6}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onKeyPress={restrictAlpha}
            />
            {touched?.daily_price && errors?.daily_price && (
              <span className="text-start required">{errors?.daily_price}</span>
            )}
          </div>
          <div className="mb-2">
            <p className="font12 font700 colordark mb-1">
              Minimum Rental Period <span className="error">*</span>
            </p>
            <Form.Control
              type="text"
              maxLength={3}
              name="minimum_rental_period"
              value={values?.minimum_rental_period}
              placeholder="Minimum rental period (days)"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onKeyPress={NumberWithdecimal}
            />
            {touched?.minimum_rental_period &&
              errors?.minimum_rental_period && (
                <span className="text-start required">
                  {errors?.minimum_rental_period}
                </span>
              )}
          </div>
          <div>
            <p className="font12 font700 colordark mb-1">
              Maximum Rental Period {/* <span className="error">*</span> */}
            </p>
            <Form.Control
              type="text"
              name="maximum_rental_period"
              value={values?.maximum_rental_period}
              placeholder=" maximum rental period (days)"
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={3}
              onKeyPress={NumberWithdecimal}
              onKeyDown={handleKeyDown}
            />
            {touched?.maximum_rental_period &&
              errors?.maximum_rental_period && (
                <span className="text-start required">
                  {errors?.maximum_rental_period}
                </span>
              )}
          </div>
        </Col>
        <div className="mt-3">
          <Button
            type="button"
            className="btn btn-theme mb-0 mx-1"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="btn btn-theme mb-0 ms-2 mx-1"
            onClick={() => backAction()}
          >
            Back
          </Button>
        </div>
      </Row>
      {loading && <Loader />}
    </>
  );
};

export default Pricing;
