/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Nodata from "../Commons/CommonModals/Nodata";
import Dropdown from "../Commons/Dropdown";
import Header from "../Commons/Headers/Header";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Loader from "../Commons/Loader/Loader";
import MobileFooter from "../Commons/MobileFooter/MobileFooter";
import MobileRenterFooter from "../Commons/MobileFooter/MobileRenterFooter";
import { categoryItemsList, getCategory } from "../Services/APIServices";
import {
  CalendarIcon,
  CrossDarkIcon,
  FilterIcon,
  MapLightIcon,
  SearchGrayIcon,
  UpDarkIcon,
} from "../SvgIcons/allIcons";
import {
  ACTIVE_STATE,
  DEFAULT_CURRENCY,
  HIGH_TO_LOW,
  LENDER,
  LOW_TO_HIGH,
  PERPAGE_TEN,
  RENTER,
} from "../globals/constants";
import useDocumentTitle from "../globals/useDocumentTitle";
import useRole from "../hooks/useRole";
import Map from "./Renter/Checkout/Map";
import { imageBase } from "../globals/Config";

const MobileCategoryItem = () => {
  useDocumentTitle("Shaxify - MarketPlace");
  const navigate = useNavigate();
  const location = useLocation();
  const listRef = useRef();
  const role = useRole();
  const token = localStorage.getItem("token");
  const id = new URLSearchParams(location?.search).get("id");
  const [activeTab, setActiveTab] = useState("second");
  const [list, setList] = useState([]);
  const [catId, setCatId] = useState("");
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [sort_by, setSort_by] = useState("");
  const [status] = useState(ACTIVE_STATE);
  const [meta, setMeta] = useState("");
  const [loading, setLoding] = useState(true);
  const [catList, setcatList] = useState([]);
  const [selectedView, setSeletedView] = useState("grid");
  const [isShowForm, setIsShowForm] = useState(false);
  const isLogin = useSelector((state) => state?.Login?.login);
  const [showCard, setShowCard] = useState(false);
  const [showData, setShowData] = useState("");
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
  const [mapView, setMapView] = useState(false);

  useEffect(() => {
    getCategorylist();
    if (id) {
      setCatId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!wasLastList && prevPage != currPage) {
      getCategoryItems(
        id ? id : "",
        search,
        from,
        to,
        sort_by?.value,
        status,
        currPage,
        PERPAGE_TEN,
        mapView
      );
    }
  }, [currPage, wasLastList, prevPage, id, mapView]);

  const getCategoryItems = (
    id = "",
    title = "",
    from = "",
    to = "",
    sort_by = "",
    status = "",
    page = "1",
    perPage = "10",
    map_view = false
  ) => {
    categoryItemsList(
      id,
      title,
      from,
      to,
      sort_by,
      status,
      page,
      perPage,
      map_view
    )
      .then((resp) => {
        setLoding(false);
        if (resp?.status === 200) {
          if (!resp?.data?.data?.length) {
            setWasLastList(true);
            return;
          }
          setPrevPage(currPage);
          setList([...list, ...resp?.data?.data]);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoding(false));
  };

  const handleViewProduct = (id) => {
    {
      token
        ? navigate(`/renter/renter-details?id=${id}`)
        : navigate(`/renter-details?id=${id}`);
    }
  };

  const getCategorylist = () => {
    getCategory().then((resp) => {
      if (resp?.status === 200) {
        setcatList(resp?.data?.data);
      }
    });
  };

  const handleCategoryChange = (data) => {
    if (catId == data?.id) {
      navigate("/catagory-mapping?id=&t=");
      setCatId("");
    } else {
      navigate({
        pathname: location?.pathname,
        search: `id=${data?.id}&t=${data?.title}`,
      });
      setCatId(data?.id);
    }
    setList([]);
    setCurrPage(1);
    setPrevPage(0);
    setWasLastList(false);
  };

  const handleSearch = (e) => {
    setLoding(true);
    setList([]);
    setCurrPage(1);
    setPrevPage(0);
    setWasLastList(false);
  };

  const removeSearch = () => {
    setLoding(true);
    setSearch("");
    setList([]);
    setCurrPage(1);
    setPrevPage(0);
    setWasLastList(false);
  };

  const handleApplyFilter = () => {
    setList([]);
    setCurrPage(1);
    setPrevPage(0);
    setWasLastList(false);
  };

  const handleRemoveFilters = () => {
    setList([]);
    setFrom("");
    setTo("");
    setSort_by("");
    setCurrPage(1);
    setPrevPage(0);
    setWasLastList(false);
    setIsShowForm((prev) => !prev);
  };

  /***************************Infinte Scroll functionality***************************/
  const onScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const handleViewProfile = (id) => {
    if (isLogin) {
      navigate(`/renter/user-profile?user_id=${id}`);
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  return (
    <>
      <div className="mobile-fixed-header">
        {role == LENDER ? (
          <LenderHeader />
        ) : role == RENTER ? (
          <RenterHeader />
        ) : (
          <Header />
        )}
        <div className="inner-box">
          <div className="top-breadcrumb top-area mobile-breadcrumb bg-white">
            <Container>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div className="w-100">
                  <Form className="search-form">
                    <Form.Control
                      type="text"
                      className="Search"
                      aria-label="Search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        if (e.target.value == "") {
                          removeSearch();
                        }
                        setSearch(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch();
                        }
                      }}
                    />
                    <span className="searc-icon" onClick={handleSearch}>
                      <SearchGrayIcon />
                    </span>

                    {search.length ? (
                      <span className="cross" onClick={removeSearch}>
                        <CrossDarkIcon />
                      </span>
                    ) : (
                      ""
                    )}
                  </Form>
                </div>
                <div className="filter-btn">
                  {isShowForm ? (
                    <Link
                      className="btn btn-theme my-0"
                      onClick={(e) => {
                        e.preventDefault();

                        handleRemoveFilters();
                      }}
                    >
                      <CrossDarkIcon />
                    </Link>
                  ) : (
                    <Link
                      className="btn btn-theme my-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsShowForm((prev) => !prev);
                      }}
                    >
                      <FilterIcon />
                    </Link>
                  )}
                </div>
              </div>
            </Container>
          </div>
          <div className="category-icons">
            <Container>
              <div className="d-flex align-items-center justify-content-evenly">
                {catList &&
                  catList?.length > 0 &&
                  catList?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className={data?.id == catId ? "active-cat" : ""}
                        onClick={() => {
                          handleCategoryChange(data);
                          setLoding(true);
                        }}
                      >
                        <span>
                          <img
                            width="30px"
                            height="26px"
                            src={
                              data?.category_icon &&
                              imageBase + data.category_icon
                            }
                          />
                        </span>
                        <p className="font8 font500">{data?.title}</p>
                      </div>
                    );
                  })}
              </div>
            </Container>
          </div>
        </div>
      </div>
      <div
        className={selectedView == "map" ? "main_box map-view" : "main_box"}
        ref={listRef}
        onScroll={onScroll}
      >
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          {isShowForm ? (
            <div className="filterbar">
              <Container>
                <Row className="align-items-end">
                  <Col lg={8} md={12} sm={12}>
                    <Form>
                      <Row>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              From
                            </Form.Label>
                            <div className="datepicker position-relative">
                              <Form.Control
                                type="date"
                                placeholder="DD/MM/YYYY"
                                value={from}
                                onChange={(e) => {
                                  if (to && e.target.value > to) {
                                    setFrom(to);
                                    setTo(e.target.value);
                                  } else {
                                    setFrom(e.target.value);
                                  }
                                }}
                              />
                              <span className="position-absolute">
                                <CalendarIcon />
                              </span>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              To
                            </Form.Label>
                            <div className="datepicker position-relative">
                              <Form.Control
                                type="date"
                                placeholder="DD/MM/YYYY"
                                value={to}
                                onChange={(e) => {
                                  if (from && e.target.value < from) {
                                    setTo(from);
                                    setFrom(e.target.value);
                                  } else {
                                    setTo(e.target.value);
                                  }
                                }}
                                name="to"
                              />
                              <span className="position-absolute">
                                <CalendarIcon />
                              </span>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Sort by
                            </Form.Label>
                            <Dropdown
                              name="category_mapping"
                              value={sort_by}
                              clearable={true}
                              options={[
                                {
                                  label: "Total price high",
                                  value: HIGH_TO_LOW,
                                },
                                {
                                  label: "Total price low",
                                  value: LOW_TO_HIGH,
                                },
                              ]}
                              placeholder={"Select"}
                              onChange={(e) => setSort_by(e)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Link
                            className="btn btn-theme w-100 mt-3 mb-0"
                            onClick={(e) => {
                              e.preventDefault();
                              handleApplyFilter();
                            }}
                          >
                            Apply Filters
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <></>
          )}

          <div className="tab-height">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="category-boxes position-relative">
                  <Container fluid className="px-0">
                    <Row className="m-0">
                      <Col lg={4}></Col>
                      <Col lg={8} sm={12} className="p-0 h-100">
                        <div className="mappingimg overflow-hidden">
                          {showCard && (
                            <div className="map-loc-card mb-0">
                              <div className="d-flex align-items-center">
                                <div className="map-img-card">
                                  <img
                                    src={
                                      showData?.product_image
                                        ? imageBase +
                                          showData?.product_image?.images
                                        : require("../assets/images/electronics.png")
                                    }
                                    width="80px"
                                    height="80px"
                                    onClick={() => {
                                      navigate(
                                        `/renter-details?id=${showData?.id}`
                                      );
                                    }}
                                  />
                                  <Link
                                    className="cross-grn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowCard(false);
                                    }}
                                  >
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.4165 1.41699L10.5832 10.5837M1.4165 10.5837L10.5832 1.41699"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="map-card-dis">
                                  <p
                                    className="font12 font700 d-flex align-items-center gap-2 mb-0 "
                                    onClick={() =>
                                      handleViewProfile(
                                        showData?.created_by?.id
                                      )
                                    }
                                  >
                                    {showData?.created_by?.full_name
                                      ? showData?.created_by?.full_name
                                      : ""}
                                    <span className="stars-card">
                                      <ReactStars
                                        value={showData?.average_rating}
                                        count={5}
                                        size={24}
                                        color2={"#16ff96"}
                                        edit={false}
                                      />
                                    </span>
                                  </p>
                                  <div
                                    onClick={() => {
                                      navigate(
                                        `/renter-details?id=${showData?.id}`
                                      );
                                    }}
                                  >
                                    <h5 className="head16 ">
                                      {showData?.title ? showData?.title : ""}
                                    </h5>
                                    <p className="font700 font12 mb-0">
                                      {showData?.daily_price
                                        ? `${DEFAULT_CURRENCY} 
                                        ${Number(
                                          showData?.daily_price
                                        )?.toLocaleString("hi")}`
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <Map
                            props={list}
                            device={"mobile"}
                            setShowCard={setShowCard}
                            setShowData={setShowData}
                            getCategoryItems={getCategoryItems}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="category-boxes heightauto mt-4">
                  <Container>
                    <Row>
                      {list && list?.length > 0 ? (
                        list?.map((data, index) => {
                          return (
                            <Col
                              lg={3}
                              md={4}
                              sm={12}
                              key={index}
                              className="mb-3"
                            >
                              <Card className="mb-4">
                                <Card.Img
                                  onClick={() => handleViewProduct(data?.id)}
                                  variant="top"
                                  src={
                                    data?.product_image?.images
                                      ? imageBase + data?.product_image?.images
                                      : require("../assets/images/static_image.jpg")
                                  }
                                />
                                <Card.Body>
                                  <p
                                    className="font12 font700 mb-2"
                                    onClick={() =>
                                      handleViewProfile(data?.created_by?.id)
                                    }
                                  >
                                    {data?.created_by?.full_name} (
                                    {data?.rental_count})
                                    <span className="stars-card">
                                      <ReactStars
                                        value={data?.average_rating}
                                        count={5}
                                        size={24}
                                        color2={"#16ff96"}
                                        edit={false}
                                        line-height={26}
                                      />
                                    </span>
                                  </p>
                                  <h5
                                    className="head16"
                                    onClick={() => handleViewProduct(data?.id)}
                                  >
                                    {data?.title}
                                  </h5>
                                  <p className="font700 font12 mb-0">
                                    Today price : {DEFAULT_CURRENCY}
                                    {data?.daily_price
                                      ? ` ${Number(
                                          data?.daily_price
                                        ).toLocaleString("hi")}`
                                      : ""}
                                  </p>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                      ) : (
                        <>{!loading && <Nodata />}</>
                      )}
                    </Row>
                  </Container>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <div className="mobile-footer-fixed">
            {selectedView == "map" ? (
              <div className="mobile-grid-btn">
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => {
                      setMapView(false);
                      setSeletedView("grid");
                      if (listRef) {
                        listRef?.current?.scrollTo({
                          top: 0,
                        });
                        window.scrollTo(0, 0);
                      }
                      setList([]);
                      setPrevPage(0);
                      setCurrPage(1);
                      setLoding(true);
                      setWasLastList(false);
                    }}
                  >
                    <span>
                      <UpDarkIcon />
                    </span>
                    <br />
                    {meta?.total_results} Articles for rent in the selected area
                  </Nav.Link>
                </Nav.Item>
              </div>
            ) : (
              <div className="mobile-map-btn">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => {
                      setMapView(true);
                      setSeletedView("map");
                      if (listRef) {
                        listRef?.current?.scrollTo({
                          top: 0,
                        });
                        window.scrollTo(0, 0);
                      }
                      setList([]);
                      setPrevPage(0);
                      setCurrPage(1);
                      setLoding(true);
                      setWasLastList(false);
                    }}
                  >
                    <MapLightIcon /> Map
                  </Nav.Link>
                </Nav.Item>
              </div>
            )}
            {isLogin ? <MobileRenterFooter /> : <MobileFooter />}
          </div>
        </Tab.Container>

        {loading && <Loader />}
      </div>
    </>
  );
};

export default MobileCategoryItem;
