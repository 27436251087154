import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardGroup,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Selected } from "../../App";
import {
  AddAddress,
  deleteAddress,
  getAddressList,
  updateAddress,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import {
  BackIcon,
  CrossIcon,
  DeletecircleIcon,
  EditcircleIcon,
} from "../../SvgIcons/allIcons";
import { RENTER } from "../../globals/constants";
import { countryCode } from "../../utils/CountryCode";
import Loader from "../Loader/Loader";

export default function Address() {
  const { setIsSelected } = useContext(Selected);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const handleShowTwo = (data) => {
    setshowDelete(true);
    setShowData(data);
    setId(data?.id);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showDelete, setshowDelete] = useState(false);
  const handleCloseTwo = () => setshowDelete(false);
  const [showDismissDelete, setshowDismissDelete] = useState(false);
  const handleCloseThree = () => setshowDismissDelete(false);
  const [showData, setShowData] = useState("");
  const [list, setList] = useState([]);
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  useEffect(() => {
    addressList();
  }, []);

  const addressList = () => {
    getAddressList().then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.data);
      }
    });
  };

  const validationSchema = Yup.object({
    address1: Yup.string().required("This field is required").trim(),
    state: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    country: Yup.string().required("This field is required."),
    zipcode: Yup.string()
      .matches(/^[0-9]{5}/, "Enter a correct ZipCode it should be number")
      .min(6, "Minimum 6 digit required.")
      .max(10, "Maximum 10 digit required.")
      .required("This field is required."),
  });

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    handleBlur,
    touched,
    resetForm,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      state: "",
      city: "",
      country: "",
      zipcode: "",
      latitude: "1.12345",
      longitude: "1.12345",
      mobile_no: "",
      country_code: "",
      iso_code: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let body = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude ? values.latitude : 0,
        longitude: values.longitude ? values.longitude : 0,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });

      if (id) {
        formData.append("address_id", id);
      }
      if (id) {
        setLoading(true);
        updateAddress(formData).then((resp) => {
          setSubmitting(false);
          setLoading(false);
          if (resp?.status === 200) {
            setShow(false);
            setIsSelected(false);
            addressList();
            resetForm();
          } else {
            showNotification("danger", resp?.data?.message);
          }
          setId("");
        });
      } else {
        setLoading(true);
        AddAddress(formData).then((resp) => {
          setSubmitting(false);
          setLoading(true);
          if (resp?.status === 200) {
            setLoading(false);
            setShow(false);
            setIsSelected(false);
            addressList();
            resetForm();
          }
          if (resp?.status == 400) {
            setShow(false);
            setLoading(false);
            resetForm();
          }
        });
      }
    },
  });

  const handleShow = (data) => {
    setShow(true);
    if (data) {
      setId(data?.id);
      setValues({
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        country: data.country,
        zipcode: data.zipcode,
        latitude: data.latitude,
        longitude: data.longitude,
      });
    }
  };

  const handleDeleteAddress = () => {
    const formData = new FormData();
    formData.append("address_id", id);
    deleteAddress(formData).then((resp) => {
      if (resp?.status === 200) {
        setId("");
        setshowDelete(false);
        setshowDismissDelete(true);
        addressList();
      }
    });
  };

  const handleSaveButton = () => {
    if (list.length == 0) {
      showNotification("danger", "Add at least one address to proceed");
    } else {
      if (userRole == RENTER) {
        navigate("/renter/profile?atab=fourth");
      } else {
        navigate("/lender/profile?atab=fourth");
      }
    }
  };

  const backAction = () => {
    if (userRole == RENTER) {
      navigate("/renter/profile?atab=second");
    } else {
      navigate("/lender/profile?atab=second");
    }
  };

  const handlePlaces = (place) => {
    place?.address_components?.map((item) => {
      if (item?.types?.includes("country")) {
        setFieldValue("country", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        setFieldValue("state", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setFieldValue("city", item?.long_name);
      }
      if (item?.types?.includes("postal_code")) {
        setFieldValue("zipcode", item?.long_name);
      }
    });
    setFieldValue("address1", place?.formatted_address);
    setFieldValue("latitude", place?.geometry?.location?.lat());
    setFieldValue("longitude", place?.geometry?.location?.lng());
  };

  return (
    <Row>
      <Col lg={10} md={10} sm={12} className="px-4">
        <p className="head16 d-flex align-items-center gap-3">
          <Link
            className="d-block d-lg-none d-md-none"
            onClick={(e) => {
              e.preventDefault();
              setIsSelected(false);
            }}
          >
            <BackIcon />
          </Link>
          Addresses
        </p>
        <Row>
          {list &&
            list?.map((data, index) => {
              return (
                <Col lg={4} md={6} sm={12} key={index}>
                  <Card className="addresscard mt-2">
                    <div className=" justify-content-between w-100">
                      <div className="d-flex justify-content-end mb-2">
                        <div className="d-block d-lg-none d-md-none">
                          <Link
                            to={
                              userRole == RENTER
                                ? `/renter/addnewaddress-mobile`
                                : `/lender/addnewaddress-mobile`
                            }
                            state={data}
                          >
                            <EditcircleIcon />
                          </Link>
                        </div>
                        <div className="d-none d-lg-block d-md-block">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              handleShow(data);
                            }}
                          >
                            <EditcircleIcon />
                          </Link>
                        </div>
                        &nbsp;&nbsp;
                        <Link onClick={() => handleShowTwo(data)}>
                          <DeletecircleIcon />
                        </Link>
                      </div>
                      <p className="mb-0">
                        {data?.address1}
                        {","}
                        <br />
                        {data?.address2}
                        <br />
                        {data?.city}
                        {","}
                        <br />
                        {data?.state}
                        {","}
                        <br />
                        {data?.country}
                        <br />
                        {data?.zipcode}
                      </p>
                    </div>
                  </Card>
                </Col>
              );
            })}

          <Col lg={4} md={6} sm={12}>
            <div className="d-none d-lg-block d-md-block">
              <Link
                className="h-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleShow();
                  resetForm();
                  setId("");
                }}
              >
                <CardGroup className="addresscard addadress d-flex mt-2 align-items-center justify-content-center">
                  Add new Address
                </CardGroup>
              </Link>
            </div>

            <div className="d-block d-lg-none d-md-none">
              {
                <Link
                  to={
                    userRole == RENTER
                      ? "/renter/addnewaddress-mobile"
                      : "/lender/addnewaddress-mobile"
                  }
                  className="d-block d-md-none"
                >
                  <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                    <p className="mb-0 font700">Add new address</p>
                  </CardGroup>
                </Link>
              }
            </div>
          </Col>
          <div className="d-flex mt-4">
            <Button
              className="btn btn-theme font700 mx-1 d-none d-lg-block d-md-block"
              onClick={handleSaveButton}
            >
              Save
            </Button>
            <Button
              className="btn btn-theme font700 mx-1 d-none d-lg-block d-md-block"
              onClick={() => backAction()}
            >
              Back
            </Button>
          </div>
        </Row>
      </Col>

      {/* Add Address Details  */}

      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
        className="addresmodal text-start"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">
              {id ? "Update address" : "Add new address"}
            </h4>

            <Link
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CrossIcon />
            </Link>
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Address 1 <span className="error">*</span>
              </Form.Label>
              <Autocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                placeholder="Address 1"
                className="form-control"
                name="address1"
                value={values?.address1}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "in" },
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                onPlaceSelected={(place) => {
                  handlePlaces(place);
                }}
              />

              <span className="text-start required">
                {touched.address1 && errors.address1}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Address 2{/* <span className="error">*</span> */}
              </Form.Label>
              <Form.Control
                type="text"
                name="address2"
                placeholder="Address 2"
                value={values?.address2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <span className="text-start required">
                {touched.address2 && errors.address2}
              </span> */}
            </Form.Group>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    City <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={handleChange}
                    value={values?.city}
                    onBlur={handleBlur}
                  />
                  <span className="text-start required">
                    {touched.city && errors.city}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    State <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    name="state"
                    value={values?.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="text-start required">
                    {touched.state && errors.state}{" "}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Country <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    name="country"
                    value={values?.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <span className="text-start required">
                    {touched.country && errors.country}
                  </span>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Zip Code <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zipcode"
                    maxLength="10"
                    placeholder="Zip Code"
                    onChange={handleChange}
                    value={values?.zipcode}
                    onBlur={handleBlur}
                  />
                  <span className="text-start required">
                    {touched.zipcode && errors.zipcode}{" "}
                  </span>
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Country Code <span className="error">*</span>
                  </Form.Label>
                  <Dropdown
                    name="country_code"
                    value={country}
                    options={countryCodeOptions()}
                    onChange={handleCountryChange}
                    onBlur={() => setFieldTouched("country_code", true)}
                  />

                  <span className="text-start required">
                    {touched.country_code && errors.country_code}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Phone Number <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="mobile_no"
                    placeholder="Enter phone number "
                    value={values?.mobile_no}
                    onBlur={handleBlur}
                    maxLength={10}
                  />
                  <span className="text-start required">
                    {touched.mobile_no && errors.mobile_no}{" "}
                  </span>
                </Form.Group>
              </Col> */}
            </Row>

            <Button
              className="btn btn-theme font700 mb-0 w-100"
              onClick={handleSubmit}
            >
              {id ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* delete user  Address */}

      <Modal
        show={showDelete}
        onHide={handleCloseTwo}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete Address</h4>
            <a onClick={handleCloseTwo}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Do you want remove following address</p>

          <Row>
            <Col lg={12}>
              <CardGroup className="addresscard">
                <div className="d-flex justify-content-between w-100">
                  <p className="mb-0">
                    <br />
                    {showData?.address1}
                    <br />
                    {showData?.address2}
                    <br />
                    {showData?.city}
                    <br />
                    {showData?.state}
                    <br />
                    {showData?.country}
                    <br />
                    {showData?.zipcode}
                  </p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <br />
          <Button
            className="btn btn-danger mb-0 w-100"
            onClick={handleDeleteAddress}
          >
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDismissDelete}
        onHide={handleCloseThree}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Address Deleted!</h4>
            <a onClick={handleCloseThree}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Following address has been deleted!</p>

          <Button
            className="btn btn-theme mb-0 w-100"
            onClick={handleCloseThree}
          >
            Dismiss
          </Button>
        </Modal.Body>
      </Modal>
      {loading ? <Loader /> : null}
    </Row>
  );
}
