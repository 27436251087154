/**
 * 
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*
*/

import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import { RenterSummaryApi, reportUserApi, addSupport } from "../../Services/APIServices";
import { BtnRightIcon, CrossIcon, ShieldIcon, SupprotBigIcon } from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import {
  BIWEEKLY,
  DEFAULT_CURRENCY,
  MONTHLY,
  OFFENSIVE,
  OTHER,
  SCAMMING,
  THIRTY,
  WEEKLY,
  RENTER
} from "../../globals/constants";
import showNotification from "../../Services/NotificationService";
import { ContactSupport } from "../CommonModals/ContactSupport";
import useRole from "../../hooks/useRole";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";

export const MessageRentalSummary = (props) => {
  const { productId, orderId, receiverId, currentUser } = props;
  const [summary, setSummary] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [showSupport, setShowSupport] = useState(false);
  const role = useRole();

  const handleClose = () => {
    setShow(false)
    setShowSupport(false)
  };
  const handleShowSupport = () => setShowSupport(true);

  useEffect(() => {
    if (orderId) {
      getRentalSummary(orderId);
    }
  }, [orderId]);

  const getRentalSummary = (id) => {
    RenterSummaryApi(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setSummary(resp?.data?.data);
      }
    });
  };

  const validationSchema = Yup.object({
    report_type: Yup.string().required("Select reason to proceed"),
  });

  const { handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      report_type: "",
    },
    validationSchema,

    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);

      let formData2 = new FormData();
      formData2.append("user_id", receiverId);
      formData2.append("report_type", values?.report_type);

      reportUserApi(formData2).then((resp) => {
        setSubmitting(false);
        if (resp?.data?.status === 200) {
          showNotification("success", resp?.data?.message);
        }
        resetForm();
        handleClose();
      });
    },
  });

  const numberOfDays = () => {
    if (summary?.booked_to && summary?.booked_from) {
      let diff = moment(summary?.booked_to).diff(summary?.booked_from, "days");
      return Number(diff) + 1;
    } else {
      return 0;
    }
  };

  const discountType = (days) => {
    if (WEEKLY <= days < BIWEEKLY) {
      return "Weekly discount";
    } else if (BIWEEKLY <= days < MONTHLY) {
      return "Bi-Weekly discount";
    } else if (MONTHLY <= days < THIRTY) {
      return "Monthly discount";
    } else {
      return "Discount";
    }
  };

  const discountCalculator = (days) => {
    if (WEEKLY <= days && days < BIWEEKLY) {
      return summary?.product_detail?.weekly_discount;
    } else if (BIWEEKLY <= days && days < MONTHLY) {
      return summary?.product_detail?.biweekly_discount;
    } else if (MONTHLY <= days && days <= THIRTY) {
      return summary?.product_detail?.monthly_discount;
    } else {
      return summary?.product_detail?.discount;
    }
  };

  const TotalAmountCalculator = () => {
    let rent =
      (Number(summary?.sub_total ? summary?.sub_total : 0) *
        (100 - Number(discountCalculator(numberOfDays())))) /
      100;

    return (
      Number(rent) +
      Number(summary?.product_detail?.shaxify_renter_fee) +
      Number(summary?.shipping_fee?.shipping_fee)
    );
  };

  const navigate = useNavigate();

  const handleViewProduct = (data) => {
    navigate(
      `/renter/my-rental-detail?id=${data?.id}&pid=${data?.product_detail?.product_id}`
    );
  };

  const splitFullName = (fullName = "") => {
    // Ensure fullName is a string and trim extra spaces
    const nameParts = fullName.trim().split(" ").filter((part) => part);

    let first_name = "";
    let middle_name = "";
    let last_name = "";

    // Assign values based on the number of parts
    if (nameParts.length === 1) {
      first_name = nameParts[0];
    } else if (nameParts.length === 2) {
      first_name = nameParts[0];
      last_name = nameParts[1];
    } else if (nameParts.length >= 3) {
      first_name = nameParts[0];
      last_name = nameParts[nameParts.length - 1];
      middle_name = nameParts.slice(1, nameParts.length - 1).join(" ");
    }

    return {
      first_name,
      middle_name,
      last_name,
    };
  };

  const discountPercentage = discountCalculator(numberOfDays()) || 0;
  const subTotal = summary?.sub_total || 0;
  const discountValue = (subTotal * discountPercentage) / 100;

  return (
    <div className="overflow-auto h70vh">
      <h4 className="mt-4 mb-2">Rental Summary</h4>
      {productId ? (
        <>
          <div className="rental-chat-img mt-3">
            <img
              src={
                summary?.product_detail?.image
                  ? imageBase + summary?.product_detail?.image
                  : require("../../assets/images/static_image.jpg")
              }
              alt=""
            />
            <div className="my-3">
              <h5 className="font16 font700 mb-2 d-flex align-items-start justify-content-between">
                {summary?.product_detail?.product_title}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleViewProduct(summary);
                  }}
                  className="float-end"
                >
                  <BtnRightIcon />
                </Link>
              </h5>
              <p className="msg font16 font400 colordark mb-1">
                {`${moment(summary?.booked_from).format("ll")} -
              ${moment(summary?.booked_to).format("ll")}`}
              </p>
            </div>
          </div>
          <ul className="users-list">
            <li
              className="d-flex align-items-start mb-3 my-3 border-bottom"
              onClick={() =>
                navigate(
                  `/renter/user-profile?user_id=${summary?.product_created_by?.id}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              {summary?.product_created_by?.profile_pic ? (
                <img
                  width="50"
                  height="50"
                  src={
                    summary?.product_created_by?.profile_pic
                      ? imageBase + summary?.product_created_by?.profile_pic
                      : require("../../assets/images/DummyImg.png")
                  }
                  alt=""
                />
              ) : (
                <div className="name-circle-img">
                  <DefaultProfileImg userDetails={splitFullName(summary?.product_created_by?.full_name)} />
                </div>
              )}
              <div className="ms-3 border-0">
                <h5 className="font16 font700 colorgreen mb-1">Your Lender</h5>
                <p className="user-name font16 font700 colordark mb-0">
                  {summary?.product_created_by?.full_name}
                </p>
                <div className="starlist ms-0">
                  <ReactStars
                    value={summary?.product_detail?.average_rating}
                    count={5}
                    size={24}
                    color2={"#16ff96"}
                    edit={false}
                  />
                </div>
              </div>
            </li>

            <li className="mb-3 ">
              <h4>Rental details</h4>
              <p className="font16 font700 colordark my-4">
                Lender send item by:
                {moment(summary?.rental_details?.lender_send_item_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font400 colordark mb-4">
                Item received by renter by:
                {moment(summary?.rental_details?.renter_received_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font400 colordark mb-4">
                Renter sends back item by:
                {moment(summary?.rental_details?.renter_send_back_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font700 colordark mb-4">
                Lender receives back item by:{" "}
                {moment(
                  summary?.rental_details?.Lender_receive_back_date
                ).format(" YYYY-MM-DD")}
              </p>
            </li>

            <li className="mb-3">
              <h4>Payment Summary</h4>
              <ul>
                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Sub Total ({numberOfDays()} days)
                  <span>
                    {DEFAULT_CURRENCY} {summary?.sub_total}
                  </span>
                </li>
                {discountCalculator(numberOfDays()) && (
                  <li className="d-flex align-items-center justify-content-between font16 font400">
                    {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                    <span>
                      {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}
                    </span>
                  </li>
                )}

                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Shaxify fee
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.product_detail?.shaxify_renter_fee
                      ? Number(
                        summary?.product_detail?.shaxify_renter_fee
                      )?.toLocaleString("hi")
                      : 0}
                  </span>
                </li>
                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Shipping fee
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.shipping_fee?.shipping_fee
                      ? Number(
                        summary?.shipping_fee?.shipping_fee
                      )?.toLocaleString("hi")
                      : 0}
                  </span>
                </li>
                <li className="d-flex align-items-center justify-content-between font16 font700">
                  Total
                  <span>
                    {DEFAULT_CURRENCY}
                    {Number(TotalAmountCalculator()).toLocaleString("hi")}
                  </span>
                </li>
              </ul>
            </li>

            <li className="mb-3 ">
              <h4 className="d-flex justify-content-between align-items-center">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowSupport();
                  }}
                  className="colordark"
                >
                  Get help
                </Link>
                <SupprotBigIcon />
              </h4>
            </li>

            <li className="mb-3">
              <h4 className="d-flex justify-content-between align-items-center">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleShow();
                  }}
                  className="colordark"
                >
                  Report Lender
                </Link>
                <ShieldIcon />
              </h4>
            </li>
          </ul>
        </>
      ) : (
        <div className="justify-content-center d-flex align-items-center h60vh">
          <p className="font16 mb-0 ">No associated rental</p>
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Report Lender</h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="px-0 pb-0 pt-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-left">
                This info will only be shared with Shaxify
              </p>
            </div>
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={SCAMMING}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    I think they are Scamming Me
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OFFENSIVE}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">Offensive</p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OTHER}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    Something else
                  </p>
                </div>

                {
                  <span className="text-start required">
                    {errors?.report_type}
                  </span>
                }

                <div className="text-center">
                  <button
                    className="btn btn-theme mt-3 mb-1 w-50 mx-auto"
                    type="button"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    Send
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <ContactSupport
        showSupportModal={showSupport}
        currentUser={currentUser}
        handleClose={handleClose}
      />

    </div>
  );
};

export default MessageRentalSummary;