import { useGoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Loader from "../Commons/Loader/Loader";
import { isLogin } from "../Redux/LoginSlice";
import FacebookLogin from "react-facebook-login";
import { SignUpAPI, socialLogin } from "../Services/APIServices";
import {
  ClosedEyeGreyIcon,
  EyeGreyIcon,
  FacebookBlueIcon2,
  GooglePlusIcon,
  KeyGreyIcon,
  UserGreyIcon,
} from "../SvgIcons/allIcons";
import { FACEBOOK, GOOGLE, LENDER, RENTER } from "../globals/constants";
import axios from "axios";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email()
      .required("Email required")
      .max(70, "Maximum lenght is 70 characters.")
      .min(5, "Need at least 5 characters.")
      .matches(/^[a-zA-Z0-9_.+-]+[@]\w+[.]\w{2,3}/, "Please enter valid email"),

    password: Yup.string()
      .required("Password required")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character."
      )
      .trim(),
    confirm_password: Yup.string()
      .required("Confirm password required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .trim(),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
      role_id: 3,
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("email", values.email);
      formData.append("role_id", RENTER);
      formData.append("password", values.password);
      formData.append("confirm_password", values.confirm_password);
      setLoading(true);
      SignUpAPI(formData)
        .then((resp) => {
          setLoading(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            dispatch(isLogin(resp?.data?.data));
            localStorage.setItem("token", resp?.data?.data?.token);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(resp?.data?.data)
            );
            if (resp?.data?.data?.role_id == RENTER) {
              navigate("/renter/home");
            }
            resetForm();
          }
        })
        .catch((err) => console.log(err));
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        setLoading(true);
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );

        let formData = new FormData();
        formData.append("email", userInfo?.data?.email);
        formData.append("profile_pic", userInfo?.data?.picture);
        formData.append("full_name", userInfo?.data?.name);
        formData.append("social_id", userInfo?.data?.sub);
        formData.append("social_type", GOOGLE);

        let resp = await socialLogin(formData);

        if (resp?.status === 200) {
          setLoading(false);
          dispatch(isLogin(resp?.data?.data));
          localStorage.setItem("token", resp?.data?.data?.token);
          localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
          if (resp?.data?.data?.role_id == LENDER) {
            navigate("/lender/home");
          }
          if (resp?.data?.data?.role_id == RENTER) {
            navigate("/renter/home");
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const responseFacebook = async (response) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("email", response?.data?.email);
      formData.append("profile_pic", response?.data?.picture);
      formData.append("full_name", response?.data?.name);
      formData.append("social_id", response?.data?.sub);
      formData.append("social_type", FACEBOOK);

      let resp = await socialLogin(formData);

      if (resp?.status === 200) {
        setLoading(false);
        dispatch(isLogin(resp?.data?.data));
        localStorage.setItem("token", resp?.data?.data?.token);
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        if (resp?.data?.data?.role_id == LENDER) {
          navigate("/lender/home");
        }
        if (resp?.data?.data?.role_id == RENTER) {
          navigate("/renter/home");
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form>
        <Form.Group className="mb-3 position-relative">
          <div className="user-icon">
            <UserGreyIcon />
          </div>
          <Form.Control
            name="email"
            value={values?.email}
            type="email"
            autoComplete="false"
            placeholder="Email"
            className="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched?.email && errors?.email && (
            <span className="text-start required">{errors?.email}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-3 position-relative">
          <div className="key-icon">
            <KeyGreyIcon />
          </div>
          <Form.Control
            name="password"
            value={values?.password}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="password"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div
            className="eye-icon"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
          </div>
          {touched?.password && errors?.password && (
            <span className="text-start required">{errors?.password}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-3 position-relative">
          <div className="key-icon">
            <KeyGreyIcon />
          </div>
          <Form.Control
            value={values?.confirm_password}
            name="confirm_password"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            className="password"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div
            className="eye-icon"
            onClick={() => setShowConfirmPassword((prev) => !prev)}
          >
            {showConfirmPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
          </div>
          {touched?.confirm_password && errors?.confirm_password && (
            <span className="text-start required">
              {errors?.confirm_password}
            </span>
          )}
        </Form.Group>
      </Form>
      <div className="d-flex align-items-center justify-content-center">
        <Button
          className="btn-theme w-100"
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit}
        >
          Sign Up
        </Button>
      </div>
      <h5>OR</h5>
      {/* <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        fields="name,email,picture"
        callback={responseFacebook}
        cssclassName="btn-facebook"
        textButton=""
        icon={
          <>
            <FacebookBlueIcon2 /> <span>Continue with Facebook2</span>
          </>
        }
      /> */}
      <Link className="btn w-100 btn-social mt-2" onClick={() => googleLogin()}>
        <GooglePlusIcon />
        Continue with Google
      </Link>
      {loading ? <Loader /> : null}
    </>
  );
}