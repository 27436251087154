import { useFormik } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { CrossIcon } from "../../SvgIcons/allIcons";
import { acceptOnlyNum } from "../../utils/CommonFunction";
import { addBankNew } from "../../Services/APIServices";

const AddBankModal = ({
  showAddBank,
  setShowAddBank,
  bankList,
  setLoading,
}) => {
  const handleClose = () => {
    setShowAddBank(false);
    resetForm();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required").trim(),
    account_number: Yup.number()
      .required("This field is required.")
      .typeError("invalid account number"),
    routing_number: Yup.string().required("This field is required."),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      account_number: "",
      routing_number: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let body = {
        account_holder_name: values?.name,
        account_number: values?.account_number,
        routing_number: values?.routing_number,
      };

      let formData = new FormData();
      Object.keys(body).forEach((i) => {
        formData.append(i, body[i]);
      });

      await addBankNew(formData);
      setLoading(false);
      bankList();
      handleClose();
    },
  });

  return (
    <Modal
      show={showAddBank}
      onHide={handleClose}
      className="addresmodal text-start"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h4 className="text-start">Add new bank account</h4>

          <a onClick={handleClose}>
            <CrossIcon />
          </a>
        </div>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="font700 font12 secondaycolor">
              Account holder Name <span className="error">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter account holder name"
              onChange={handleChange}
              value={values?.name}
              onBlur={handleBlur}
            />
            <span className="text-start required">
              {touched.name && errors.name}{" "}
            </span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font700 font12 secondaycolor">
              Account Number <span className="error">*</span>
            </Form.Label>
            <Form.Control
              type="text "
              name="account_number"
              placeholder="Enter account number "
              onChange={handleChange}
              value={values?.account_number}
              onBlur={handleBlur}
              onKeyPress={acceptOnlyNum}
            />

            <span className="text-start required">
              {touched.account_number && errors.account_number}
            </span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="font700 font12 secondaycolor">
              IFSC Code <span className="error">*</span>
            </Form.Label>
            <Form.Control
              type="text "
              name="routing_number"
              placeholder="Enter IFSC code"
              onChange={handleChange}
              value={values?.routing_number}
              onBlur={handleBlur}
              maxLength={11}
              onKeyPress={acceptOnlyNum}
            />

            <span className="text-start required">
              {touched.routing_number && errors.routing_number}
            </span>
          </Form.Group>

          <Button
            type="button"
            className="btn btn-theme font700 mb-0 w-100"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankModal;
