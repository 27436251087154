/**
 * 
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*
*/

import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import { RenterSummaryApi, reportUserApi, addSupport } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import {
  BtnRightIcon,
  CrossIcon,
  ShieldIcon,
  SupprotBigIcon,
} from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import {
  BIWEEKLY,
  DEFAULT_CURRENCY,
  LENDER,
  MONTHLY,
  OFFENSIVE,
  OTHER,
  RENTER,
  SCAMMING,
  THIRTY,
  WEEKLY,
} from "../../globals/constants";

import { ContactSupport } from "../CommonModals/ContactSupport";
import useRole from "../../hooks/useRole";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";

export const MessageLenderSummary = (props) => {

  const { productId, orderId, receiverId, isOpen, currentUser } = props;

  const [summary, setSummary] = useState(" ");
  const [show, setShow] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const handleClose = () => {
    setShow(false)
    setShowSupport(false)
  };
  const handleShow = () => setShow(true);
  const handleShowSupport = () => setShowSupport(true);
  const role = useRole();

  useEffect(() => {
    if (orderId) {
      getRentalSummary(orderId);
    }
  }, [orderId]);

  const getRentalSummary = (id) => {
    RenterSummaryApi(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setSummary(resp?.data?.data);
      }
    });
  };

  const validationSchema = Yup.object({
    report_type: Yup.string().required("Select reason to proceed"),
  });

  const { handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      report_type: "",
    },
    validationSchema,

    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);

      let formData2 = new FormData();
      formData2.append("user_id", receiverId);
      formData2.append("report_type", values?.report_type);

      reportUserApi(formData2).then((resp) => {
        setSubmitting(false);
        if (resp?.data?.status === 200) {
          showNotification("success", resp?.data?.message);
        }
        resetForm();
        handleClose();
      });
    },
  });

  const numberOfDays = () => {
    if (summary?.booked_to && summary?.booked_from) {
      let diff = moment(summary?.booked_to).diff(summary?.booked_from, "days");
      return Number(diff) + 1;
    } else {
      return 0;
    }
  };

  const discountType = (days) => {
    if (WEEKLY <= days < BIWEEKLY) {
      return "Weekly discount";
    } else if (BIWEEKLY <= days < MONTHLY) {
      return "Bi-Weekly discount";
    } else if (MONTHLY <= days < THIRTY) {
      return "Monthly discount";
    } else {
      return "Discount";
    }
  };

  const discountCalculator = (days) => {
    if (WEEKLY <= days && days < BIWEEKLY) {
      return summary?.product_detail?.weekly_discount;
    } else if (BIWEEKLY <= days && days < MONTHLY) {
      return summary?.product_detail?.biweekly_discount;
    } else if (MONTHLY <= days && days <= THIRTY) {
      return summary?.product_detail?.monthly_discount;
    } else {
      return summary?.product_detail?.discount;
    }
  };

  const TotalAmountCalculator = (type) => {
    let rent =
      (Number(summary?.sub_total ? summary?.sub_total : 0) *
        (100 - Number(discountCalculator(numberOfDays())))) /
      100;

    if (type == RENTER) {
      return (
        Number(rent) +
        Number(summary?.product_detail?.shaxify_renter_fee) +
        Number(summary?.shipping_fee?.shipping_fee)
      );
    } else {
      return Number(rent) - Number(summary?.product_detail?.shaxify_lender_fee);
    }
  };
  const navigate = useNavigate();

  const handleViewProduct = (data) => {
    navigate(
      `/lender/reservation-detail?id=${data?.id}&pid=${data?.product_detail?.product_id}`
    );
  };

  const discountPercentage = discountCalculator(numberOfDays()) || 0;
  const subTotal = summary?.sub_total || 0;
  const discountValue = (subTotal * discountPercentage) / 100;


  const splitFullName = (fullName = "") => {
    // Ensure fullName is a string and trim extra spaces
    const nameParts = fullName.trim().split(" ").filter((part) => part);

    let first_name = "";
    let middle_name = "";
    let last_name = "";

    // Assign values based on the number of parts
    if (nameParts.length === 1) {
      first_name = nameParts[0];
    } else if (nameParts.length === 2) {
      first_name = nameParts[0];
      last_name = nameParts[1];
    } else if (nameParts.length >= 3) {
      first_name = nameParts[0];
      last_name = nameParts[nameParts.length - 1];
      middle_name = nameParts.slice(1, nameParts.length - 1).join(" ");
    }

    return {
      first_name,
      middle_name,
      last_name,
    };
  };
  return (
    <div className={!isOpen && "overflow-scroll h70vh"}>
      <h4 className="mt-4">Rental Summary</h4>
      <ul className="users-list pe-0 pe-lg-2">
        {productId ? (
          <>
            <li className="d-flex align-items-start mb-3 my-3 border-bottom">
              {summary?.booked_by_detail?.profile_pic ? (
                <img
                  width="50"
                  height="50"
                  src={
                    summary?.booked_by_detail?.profile_pic
                      ? imageBase + summary?.booked_by_detail?.profile_pic
                      : require("../../assets/images/DummyImg.png")
                  }
                  alt=""
                />
              ) : (
                <div className="name-circle-img">
                  <DefaultProfileImg userDetails={splitFullName(summary?.booked_by_detail?.full_name)} />
                </div>
              )}
              <div className="ms-3 border-0">
                <h5 className="font16 font700 colorgreen mb-1">
                  Your Renter
                </h5>
                <p className="user-name font700 colordark mb-0">
                  {summary?.booked_by_detail?.full_name}
                </p>
                <div className="starlist">
                  <ReactStars
                    value={summary?.product_detail?.average_rating}
                    count={5}
                    size={24}
                    color2={"#ffd700"}
                    edit={false}
                  />
                </div>
                <p className="msg font16 font700 colordark my-2">
                  {summary?.product_detail?.product_title}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewProduct(summary);
                    }}
                    className="float-end"
                  >
                    <BtnRightIcon />
                  </Link>
                </p>
                <p className="msg font16 font400 colordark mb-1 ">
                  {`${moment(summary?.booked_from).format("ll")} -
              ${moment(summary?.booked_to).format("ll")}`}
                </p>
                <p className="msg font16 font400 colordark mb-1 ">
                  Total Payment: {DEFAULT_CURRENCY}
                  {Number(TotalAmountCalculator(RENTER)).toLocaleString("hi")}
                </p>
              </div>
            </li>
            <li className="mb-3">
              <h4>Rental details</h4>
              <p className="font16 font700 colordark my-4">
                Lender send item by:
                {moment(summary?.rental_details?.lender_send_item_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font400 colordark mb-4">
                Item received by renter by:
                {moment(summary?.rental_details?.renter_received_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font400 colordark mb-4">
                Renter sends back item by:
                {moment(summary?.rental_details?.renter_send_back_date).format(
                  " YYYY-MM-DD"
                )}
              </p>
              <p className="font16 font700 colordark mb-4">
                Lender receives back item by:{" "}
                {moment(
                  summary?.rental_details?.Lender_receive_back_date
                ).format(" YYYY-MM-DD")}
              </p>
            </li>

            <li className="mb-3 ">
              <h4>Renter paid</h4>
              <ul>
                <li className="d-flex align-items-center justify-content-between font16 font400">
                  <span>Sub Total</span>
                  <span>
                    {DEFAULT_CURRENCY} {summary?.sub_total}
                  </span>
                </li>
                {discountCalculator(numberOfDays()) && (
                  <li className="d-flex align-items-center justify-content-between font16 font400">
                    {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                    <span>
                      {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}
                    </span>
                  </li>
                )}

                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Shaxify fee{" "}
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.product_detail?.shaxify_renter_fee
                      ? Number(
                        summary?.product_detail?.shaxify_renter_fee
                      )?.toLocaleString("hi")
                      : 0}{" "}
                  </span>
                </li>

                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Shipping fee{" "}
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.shipping_fee?.shipping_fee
                      ? Number(
                        summary?.shipping_fee?.shipping_fee
                      )?.toLocaleString("hi")
                      : 0}{" "}
                  </span>
                </li>
                <li className="d-flex align-items-center justify-content-between font16 font700">
                  Total
                  <span>
                    {DEFAULT_CURRENCY}
                    {Number(TotalAmountCalculator(RENTER)).toLocaleString("hi")}
                  </span>
                </li>
              </ul>
            </li>
            <li className="mb-3 ">
              <h4>Lender payout</h4>
              <ul>
                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Sub Total
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.sub_total}
                  </span>
                </li>
                {discountCalculator(numberOfDays()) && (
                  <li className="d-flex align-items-center justify-content-between font16 font400">
                    {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                    <span> {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}</span>
                  </li>
                )}

                <li className="d-flex align-items-center justify-content-between font16 font400">
                  Shaxify fee
                  <span>
                    {DEFAULT_CURRENCY}
                    {summary?.product_detail?.shaxify_lender_fee
                      ? Number(
                        summary?.product_detail?.shaxify_lender_fee
                      )?.toLocaleString("hi")
                      : 0}
                  </span>
                </li>
                <li className="d-flex align-items-center justify-content-between font16 font700">
                  Total
                  <span>
                    {DEFAULT_CURRENCY}
                    {Number(TotalAmountCalculator(LENDER)).toLocaleString("hi")}
                  </span>
                </li>
              </ul>
            </li>
            <li className="mb-3 ">
              <h4 className="d-flex justify-content-between align-items-center">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowSupport();
                  }}
                  className="colordark"
                >
                  Get help
                </Link>
                <SupprotBigIcon />
              </h4>
            </li>
            <li className="mb-3 ">
              <h4 className="d-flex justify-content-between align-items-center">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleShow();
                  }}
                  className="colordark"
                >
                  Report Renter
                </Link>
                <ShieldIcon />
              </h4>
            </li>
          </>
        ) : (
          <div className="justify-content-center d-flex align-items-center h40vh">
            <p className="font16 mb-0 ">No associated rental</p>
          </div>
        )}
      </ul>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start ">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h4 className="text-start ">Report Renter</h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="px-0 pb-0 pt-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-left">
                This info will only be shared with Shaxify
              </p>
            </div>
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={SCAMMING}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    I think they are Scamming Me
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OFFENSIVE}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">Offensive</p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OTHER}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    Something else
                  </p>
                </div>

                {
                  <span className="text-start required">
                    {errors?.report_type}
                  </span>
                }

                <div className="text-center">
                  <button
                    className="btn btn-theme mt-3 mb-1 w-50 mx-auto"
                    type="button"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    Send
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <ContactSupport
        showSupportModal={showSupport}
        currentUser={currentUser}
        handleClose={handleClose}
      />

    </div>
  );
};

export default MessageLenderSummary;
