
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { addSupport } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { CrossIcon } from "../../SvgIcons/allIcons";
export const ContactSupport = (props) => {

     const { currentUser, showSupportModal, handleClose } = props;    
     const [user, setUser] = useState(currentUser || null);    

     const formikSupport = useFormik({
          initialValues: {
               full_name: user?.full_name,
               email: user?.email,
               message: "",
          },
          validationSchema: Yup.object({
               full_name: Yup.string().required("Please add your name.").trim(),
               email: Yup.string()
                    .required("Your email is required.")
                    .matches(
                         /^[a-zA-Z0-9_.+-]+[@]\w+[.]\w{2,3}/,
                         "Please enter valid email"
                    ),
               message: Yup.string().required("This field is required."),
          }),
          onSubmit(values, { setSubmitting, resetForm }) {
               setSubmitting(true);
               let body = {
                    full_name: values.full_name,
                    email: values.email,
                    message: values.message,
               };
               addSupport(body).then((resp) => {
                    setSubmitting(false);
                    if (resp?.status === 200) {
                         showNotification("success", resp?.data?.message);
                         resetForm();
                    } else {
                         showNotification("danger", resp?.data?.message);
                    }
                    handleClose();
               });
          },
     });

     return (
          <Modal
               show={showSupportModal}
               onHide={handleClose}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               className="request-help"
          >
               <div className="modal-body text-start ">
                    <div className="d-flex justify-content-between align-items-center mb-2 ">
                         <h4 className="text-start ">Contact Support</h4>
                         <a onClick={handleClose}>
                              <CrossIcon />
                         </a>
                    </div>
                    <Modal.Body className="px-0 pb-0 pt-0">
                         <Row>
                              <Col sm={12} className="mx-auto">
                                   <Form onSubmit={formikSupport.handleSubmit}>
                                        <Form.Group className="mb-3">
                                             <Form.Label className="font700 font12 secondaycolor">
                                                  Your name <span className="error">*</span>
                                             </Form.Label>
                                             <Form.Control
                                                  onChange={formikSupport.handleChange}
                                                  onBlur={formikSupport.handleBlur}
                                                  type="text"
                                                  placeholder="Your name"
                                                  name="full_name"
                                                  value={formikSupport.values.full_name}
                                             />
                                             {formikSupport.touched.full_name && formikSupport.errors.full_name && (
                                                  <span className="text-start required">
                                                       {formikSupport.errors.full_name}
                                                  </span>
                                             )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                             <Form.Label className="font700 font12 secondaycolor">
                                                  Your email <span className="error">*</span>
                                             </Form.Label>
                                             <Form.Control
                                                  onChange={formikSupport.handleChange}
                                                  onBlur={formikSupport.handleBlur}
                                                  type="text"
                                                  placeholder="User email"
                                                  name="email"
                                                  value={formikSupport.values.email}
                                             />
                                             {formikSupport.touched.email && formikSupport.errors.email && (
                                                  <span className="text-start required">
                                                       {formikSupport.errors.email}
                                                  </span>
                                             )}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                             <Form.Label className="font700 font12 secondaycolor">
                                                  Tell us about your problem <span className="error">*</span>
                                             </Form.Label>
                                             <Form.Control
                                                  onChange={formikSupport.handleChange}
                                                  onBlur={formikSupport.handleBlur}
                                                  name="message"
                                                  as="textarea"
                                                  rows={3}
                                                  value={formikSupport.values.message}
                                                  type="text"
                                             />
                                             {formikSupport.touched.message && formikSupport.errors.message && (
                                                  <span className="text-start required">
                                                       {formikSupport.errors.message}
                                                  </span>
                                             )}
                                        </Form.Group>

                                        <div className="text-center">
                                             <button
                                                  className="btn btn-theme mt-3 mb-1 w-50 mx-auto"
                                                  type="submit"
                                                  disabled={formikSupport.isSubmitting}
                                             >
                                                  Send
                                             </button>
                                        </div>
                                   </Form>
                              </Col>
                         </Row>
                    </Modal.Body>
               </div>
          </Modal>
     );
}