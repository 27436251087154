import React from 'react';

const DefaultProfileImg = ({ userDetails }) => {
  // Create initials from the first and last names
  const fName = userDetails?.first_name ? userDetails?.first_name : '';
  const lName = userDetails?.last_name ? userDetails?.last_name : '';
  const initials = `${fName?.[0] || ''}${lName?.[0] || ''}`.toUpperCase();

  return (
    <>
      <div className="initials-circle">
        {initials}
      </div>      
    </>
  );
};

export default DefaultProfileImg;
